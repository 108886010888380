<template>
  <div class="d-flex">
    <div>
      <div class="m-1 bus">
        <div v-for="(seatRow, index) in busSchema" :key="index" class="seat-row" >
          <div v-for="(seat, seatIndex) in seatRow" :key="index + '_' + seatIndex" class="seat" :class="[{'is-driver': seat.isDriver, 'bordered': seat.number, 'noSale': hasExceptedSeat(seat.number) || hasSeatsOnly(seat.number) }, seat.status]" @click="clickSeat(seat.number)">
            <span v-if="seat.isDriver" style="color: #fff">В</span>
            <span>{{seat.number}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BusSchema",
  props: {
    initSchema: { required: true },
    tickets: { required: true },
    exceptSeats: { required: true },
    seatsOnly: { required: true },
  },
  mounted() {
    console.log('seatsOnly length')
    console.log(this.seatsOnly)
    console.log(this.seatsOnly.length)
  },
  data() {
    return {
      schema: this.initSchema,
    }
  },
  computed: {
    ...mapState({
      bookings: state => state.MyBooks.bookings
    }),
    busSchema: function () {
      let schema = JSON.parse(JSON.stringify(this.schema))

      schema.map(row => row.map(seat => {
        const ticket = this.tickets.find(ticket => ticket.seatNumber === seat.number)
        if (ticket) {
          seat.status = ticket.status
        }

        return { ...seat }
      }))

      return { ...schema }
    }
  },
  methods: {
    hasSeatsOnly(seatNumber) {
      if (!seatNumber) {
        return false
      }

      if (this.seatsOnly.length === 0) {
        return false
      }

      return !this.seatsOnly.includes(seatNumber.toString())
    },
    hasExceptedSeat(seatNumber) {
      if (!seatNumber) {
        return false
      }

      return this.exceptSeats.includes(seatNumber.toString())
    },
    clickSeat(number) {
      if (!number)
        return

      if (this.hasExceptedSeat(number))
        return

      this.$emit('onSeatClicked', number)
    }
  }
}
</script>

<style scoped>
  .is-driver {
    background: #464646;
    border-color: #464646;
  }
  .is-passenger {
    background: #ebebeb;
    border-color: #ebebeb;
  }
  .seat-row {
    display: flex;
  }
  .seat {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    margin: 5px;
    width: 25px;
    height: 25px;
    text-align: center;
  }
  .bus{
    border: 1px solid #eee;
    border-radius: 8px;
  }
  .selected {
    background-color: #9f3333 !important;
    border-color: #9f3333 !important;
    color: #ffffff !important;
  }
  .noSale {
    background-color: #cccccc;
    border-color: #cccccc !important;
  }
  .paid {
    background-color: #3969c9;
    border-color: #3969c9 !important;
    color: #fff;
  }
  .booked,.booking,.prepared_booking {
    background-color: #ffdf94;
    border-color: #ffdf94 !important;
  }
  .bordered {
    border: 1px solid #eee;
  }
</style>