export function phoneFormat(value) {
    if (value === '') {
        return ''
    }

    let replacedValue = value.replace(/\D/g, '')
    return ('+' + replacedValue).replace(/\+(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1($2)$3-$4-$5')
}

export function validatePhone(phone) {
    return /^\+\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(phone)
}