<template>
  <b-row>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Выбор мест"
        hide-footer
        size="xl"
    >
      <CreateBookingForm ref="form" btn-title="Добавить" btn-variant="primary" @onSave="createOrder" @acceptBooking="acceptBooking" @declineBooking="declineBooking"/>
    </b-modal>
    <b-col cols="12" class="d-flex align-items-stretch mb-4">
      <b-overlay
          class="w-100"
          :show="loading"
      >
        <b-card>
            <b-row>
              <b-col md="3">
                <b-form-select :value="filters.departurePoint" @change="setDestination($event)">
                  <option :value="null">Выберите пункт отправления</option>
                  <option v-for="departure in departures" :value="departure.id" :key="departure.id">{{ departure.name }}</option>
                </b-form-select>
              </b-col>
              <b-col md="3">
                <b-form-select :value="filters.destinationPoint" :disabled="!departurePointIsSelected || loading" @change="setFilterByKey('destinationPoint', $event)">
                  <option :value="null">Выберите пункт прибытия</option>
                  <option v-for="destination in destinations" :value="destination.id" :key="destination.id">{{ destination.name }}</option>
                </b-form-select>
              </b-col>
              <b-col md="3">
                <div class="date-container">
                  <b-form-datepicker :value="filters.date" locale="ru" :start-weekday="1" :disabled="!filters.destinationPoint" label-no-date-selected="Выберите дату" @input="setFilterByKey('date', $event)"/>
                </div>
              </b-col>
              <b-col md="3">
                <b-button variant="success" @click="loadTrips" :disabled="!showTripsBtnIsActive">Показать рейсы</b-button>
              </b-col>
            </b-row>
        </b-card>
      </b-overlay>
    </b-col>
    <b-col v-if="trips.length" cols="12" class="d-flex align-items-stretch w-100">
      <b-card class="w-100 mb-4">
        <b-table :items="trips" :fields="fields" :small="true">
          <template #cell(actions)="data">
            <b-button v-if="data.item.status === 'planned'" variant="success" size="sm" @click="createOrder(data.item)">Забронировать</b-button>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col cols="12">
      <p v-if="searchStatus === 'EMPTY'" class="d-flex align-items-stretch w-100">Не найдено результатов.</p>
    </b-col>
  </b-row>
</template>

<script>
import CreateBookingForm from "@/components/blocks/CreateBookingForm";
import {mapState} from "vuex";
import moment from 'moment';
import { toRubles } from "@/utils/money";
import { toKm } from "@/utils/distance";
import { toDays } from "@/utils/time";
import departuresApi from "@/api/departures.api";

export default {
  name: "SearchDepartures",
  data() {
    return {
      fields: [
        {key: 'route.number', label: 'Номер'},
        {key: 'route.name', label: 'Маршрут'},
        {key: 'timeStart', label: 'Начало', formatter: value => moment(value).format('DD.MM.YYYY HH:mm') },
        {key: 'distance', label: 'Дистанция', formatter: value => toKm(value)},
        {key: 'durationTime', label: 'Время в пути', formatter: value => toDays(value)},
        {key: 'cost', label: 'Цена', formatter: value => toRubles(value) },
        {key: 'seats', label: 'Кол-во мест'},
        {key: 'paidSeats', label: 'Куплено мест'},
        {key: 'actions', label: ''}
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Departures.loading,
      departures: state => state.Departures.departures,
      destinations: state => state.Departures.destinations,
      searchStatus: state => state.Departures.searchStatus,
      trips: state => state.Departures.trips,
      filters: state => state.Departures.filters
    }),
    departurePointIsSelected: function () {
      return !!this.filters.departurePoint;
    },
    showTripsBtnIsActive: function () {
      return this.filters.departurePoint && this.filters.destinationPoint && this.filters.date
    },
  },
  components: {
    CreateBookingForm
  },
  methods: {
    declineBooking() {
      this.$store.dispatch('MyBooks/declineBooking').then(() => {
        this.$bvToast.toast('Бронь была успешно удалена.', {
          title: 'Успех',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: true
        })
      })
    },
    acceptBooking(form) {
      this.$store.dispatch('MyBooks/acceptBooking', form).then((bookingId) => {
          this.$bvToast.toast('Бронь была успешно создана. Бронь необходимо оплатить в течении 2-х часов.', {
            title: 'Успех',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true
          })
          this.$refs.modal.hide()
          this.$emit('bookingWasAccepted', bookingId)
      })
    },
    createOrder(value) {
      this.$store.dispatch('App/startLoad')
      departuresApi.getById(value.id, value.fromFilter.fromPointId, value.fromFilter.toPointId).then(response => {
        this.$refs.modal.show()
        setTimeout(() => {
          this.$refs.form.fillForm({
            ...value,
            ...response.data.data,
          });
        }, 0);
      }).finally(() => {
        this.$store.dispatch('App/stopLoad')
      })
    },
    setDestination(value) {
      this.$store.dispatch('Departures/loadDestinations', value);
    },
    setFilterByKey(key, value) {
      this.$store.commit('Departures/SET_FILTER_BY_KEY', { key, value })
    },
    loadTrips() {
      if (!this.showTripsBtnIsActive)
        return;

      this.$store.dispatch('Departures/loadTrips');
    }
  }
}
</script>

<style scoped>
</style>