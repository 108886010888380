<template>
  <div>
    <AcceptPayment ref="acceptPayment"/>
    <Payment ref="payment"/>
    <SearchDepartures @bookingWasAccepted="openBooking"/>
    <b-row>
      <b-col cols="6">
        <b-overlay :show="MySalesLoading">
          <b-card class="mb-4">
              <h3>Мои продажи</h3>
              <p v-if="!MySales.length">У вас еще нет ни одной продажи за сегодня.</p>
              <div v-else class="mt-4">
                <b-table :items="MySales" :fields="salesFields" :small="true">
                  <template #cell(actions)="data">
                    <b-button size="sm" variant="success" @click="openOrder(data.item.id)">Управление</b-button>
                  </template>
                </b-table>
              </div>
          </b-card>
        </b-overlay>
        <b-overlay :show="preparedBookingsLoading">
          <b-card>
            <h3>Мои брони</h3>
            <p v-if="!preparedBookings.length">У вас еще нет ни одной брони за сегодня.</p>
            <div v-else class="mt-4">
              <b-table :items="preparedBookings" :fields="preparedBookingsFields" :small="true">
                <template #cell(actions)="data">
                  <b-button size="sm" variant="success" @click="openBooking(data.item.id)">Продолжить</b-button>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-overlay>
<!--        <b-button @click="voice">
          Воспроизв
        </b-button>-->
      </b-col>
      <b-col cols="6">
        <b-overlay :show="MyRefundsLoading">
          <b-card>
            <h3>Мои возвраты</h3>
            <p v-if="!MyRefunds.length">Вы не оформили ни одного возврата сегодня.</p>
            <div v-else class="mt-4">
              <b-table :items="MyRefunds" :fields="refundFields" :small="true"></b-table>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchDepartures from "@/components/blocks/SearchDepartures";
import AcceptPayment from "@/components/blocks/AcceptPayment";
import Payment from "@/components/blocks/Payment";
import {mapState} from "vuex";
import moment from "moment";
import {toRubles} from "@/utils/money";
import VoiceSynthesizerApi from "@/api/voiceSynthesizer.api";

export default {
  name: "Dashboard",
  data() {
    return {
      preparedBookingsFields: [
        {key: 'number', label: 'Номер'},
        {key: 'expiredAt', label: 'Истекает', formatter: (date) => moment(date).format('DD.MM.YYYY HH:mm') },
        {key: 'clientPhone', label: 'Телефон'},
        {key: 'tickets', label: 'Кол.во билетов', formatter: (tickets) => tickets.length },
        {key: 'actions', label: 'Действия'}
      ],
      salesFields: [
        {key: 'number', label: 'Номер'},
        {key: 'clientPhone', label: 'Телефон'},
        {key: 'tickets', label: 'Кол.во билетов', formatter: (tickets) => tickets.length },
        {
          key: 'amount',
          label: "Сумма",
          formatter: (value) => toRubles(value)
        },
        {key: 'actions', label: 'Действия'}
      ],
      refundFields: [
        {key: 'number', label: 'Номер'},
        {key: 'clientPhone', label: 'Телефон'},
        {key: 'tickets', label: 'Кол.во билетов', formatter: (tickets) => tickets.length },
        {
          key: 'amount',
          label: "Сумма",
          formatter: (value) => toRubles(value)
        }
      ]
    }
  },
  async mounted() {
    await this.$store.dispatch('Orders/load', { page: 1 })
    await this.$store.dispatch('Departures/init')
    await this.$store.dispatch('MyPreparedBookings/load')
    await this.$store.dispatch('MyRefunds/load')
    await this.$store.dispatch('MySales/load')
    await this.$store.dispatch('MyBooks/load')
  },
  methods: {
      voice() {
        VoiceSynthesizerApi.getVoice('Внимание! объявляется посадка на рейс 160.').then(response => {
          const voice = response.data.voice
          const audio = new Audio(require('@/assets/audio/bell.mp3'));
          audio.play()
          audio.addEventListener('ended', () => {
            const voiceAudio = new Audio(voice);
            voiceAudio.play()
          })
        })
      },
      open() {
        this.$store.commit('Auth/OPEN_LOGIN_MODAL');
      },
      openBooking(bookingId) {
        this.$refs.acceptPayment.show(bookingId)
      },
      openOrder(orderId) {
        this.$refs.payment.show(orderId)
      }
  },
  computed: {
    ...mapState({
      preparedBookings: state => state.MyPreparedBookings.orders,
      preparedBookingsLoading: state => state.MyPreparedBookings.loading,
      MySalesLoading: state => state.MySales.loading,
      MySales: state => state.MySales.orders,
      MyRefunds: state => state.MyRefunds.refunds,
      MyRefundsLoading: state => state.MyRefunds.loading
    }),
  },
  components: {
    SearchDepartures,
    AcceptPayment,
    Payment
  },
};
</script>
