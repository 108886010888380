<template>
  <div>
    <b-modal
        id="modal-prevent-closing"
        ref="modalClientData"
        title="Оформление билета"
        hide-footer
        size="lg"
    >
      <UpdateTicket ref="updateTicket" @update="updateTicket"/>
    </b-modal>
    <div v-if="form.route">
      <p>{{form.detailedInfo.points[form.fromFilter.fromPointId].name}} / {{form.detailedInfo.points[form.fromFilter.toPointId].name}} . Отправление: {{getDate(form.timeStart)}}</p>
      <p>Маршрут: {{form.route.number}} / {{form.route.name}}</p>
      <p>Перевозчик: {{ form.detailedInfo.bus.carrier.carrierName }}</p>
    </div>
    <b-row>
      <b-col md="6" class="d-flex">
        <div class="mr-4">
          <div class="d-flex align-items-center">
            <div class="seat"></div>
             - Свободно
          </div>
          <div class="d-flex align-items-center">
            <div class="seat booked"></div>
            - Забронировано
          </div>
          <div class="d-flex align-items-center">
            <div class="seat paid"></div>
            - Выкуплено
          </div>
          <div class="d-flex align-items-center">
            <div class="seat selected"></div>
            - Выбрано вами
          </div>
          <div class="d-flex align-items-center">
            <div class="seat noSale"></div>
            - Нет в продаже
          </div>
        </div>
        <BusSchema
             v-if="form.detailedInfo"
             :init-schema="form.detailedInfo.bus.busSchema.seats"
             :tickets="tickets"
             :except-seats="form.detailedInfo.exceptSeats ? form.detailedInfo.exceptSeats.split(',') : []"
             :seats-only="form.detailedInfo.seatsOnly ? form.detailedInfo.seatsOnly.split(',') : []"
             @onSeatClicked="seatClicked"
        />
      </b-col>
      <b-col md="6">
        <div v-if="bookings.id" class="booking-form text-center">
          <p>До завершения процесса бронирования</p>
          <p class="expiration-time">{{ expiresIn }}</p>
          <div>
            <b-row>
              <b-col md="6">
                <b-form-group label="Телефон">
                  <b-form-input id="accept-phone" v-model="acceptForm.phone" placeholder="+7(999)999-99-99" :formatter="phoneFormat"/>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Адрес электронной почты">
                  <b-form-input id="accept-email" v-model="acceptForm.email" placeholder="test@gmail.com"/>
                </b-form-group>
              </b-col>
            </b-row>
            <div>
              <b-row>
                <b-col md="9">
                  <b-button variant="success" class="mb-3 w-100" @click="acceptBooking">Продолжить</b-button>
                </b-col>
                <b-col md="3">
                  <b-button variant="danger" class="mb-3 w-100" @click="declineBooking">Отменить</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="mt-5">
      <b-table :items="bookingsTickets" :fields="ticketFields">
        <template #cell(route)="data">
          № {{data.item.order.trip.route.number}}<br>{{data.item.order.from.name}}<br>{{data.item.order.to.name}}
        </template>
        <template #cell(departure)="data">
          <div v-html="dateTime(data.item.order.trip.points[data.item.order.from.id].dateTime)"></div>
          {{data.item.order.from.name}}
        </template>
        <template #cell(arrival)="data">
          <div v-html="dateTime(data.item.order.trip.points[data.item.order.to.id].dateTime)"></div>
          {{data.item.order.to.name}}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import 'moment-timezone';
import 'moment-duration-format';
import BusSchema from "@/components/blocks/BusSchema";
import {mapState} from "vuex";
import {toRubles} from "@/utils/money";
import UpdateTicket from "@/components/blocks/UpdateTicket";
import {phoneFormat, validatePhone} from "@/utils/phone";
moment.locale('ru')

export default {
  name: "CreateBookingForm",
  data() {
    return {
      acceptForm: {
        phone: null,
        email: null
      },
      form: {},
      ticketFields: [
        {key: 'route', label: 'Маршрут' },
        {key: 'departure', label: 'Отправление'},
        {key: 'arrival', label: 'Прибытие'},
        {key: 'seatNumber', label: 'Место'},
        {key: 'type', label: 'Тип', formatter: (value, key, item) => item.isChild ? 'ДБ' : 'ПБ' },
        {key: 'isPreferential', label: 'Льготный', formatter: (value, key, item) => item.isPreferential ? 'Да' : 'Нет' },
        {key: 'amount', label: 'Стоимость', formatter: value => toRubles(value)},
        {key: 'handLuggage', label: 'Платная ручная кладь'},
        {key: 'cashBoxFee', label: 'Кассовый сбор', formatter: value => toRubles(value)},
        {key: 'serviceFee', label: 'Сервисный сбор', formatter: value => toRubles(value)},
        {key: 'summary', label: 'Итого', formatter: (value, key, item) => toRubles(item.cashBoxFee + item.serviceFee + item.amount)},
      ]
    }
  },
  methods: {
    declineBooking() {
      if (confirm('Вы действительно хотите отменить бронирование?'))
        this.$emit('declineBooking')
    },
    acceptBooking() {
      if (!validatePhone(this.acceptForm.phone)) {
        this.$bvToast.toast('Телефон обязателен для заполнения.', {
          title: 'Не указан или неверный телефон',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: true
        })
        return
      }

      this.$emit('acceptBooking', { ...this.acceptForm })
    },
    phoneFormat: (value) => phoneFormat(value),
    showUpdateTicket(data) {
      this.$refs.modalClientData.show()
      setTimeout(() => {
        this.$refs.updateTicket.fillData(data)
      }, 0)
    },
    updateTicket(data) {
      this.$store.dispatch('MyBooks/updateTicket', { ...data }).then(() => {
          this.$refs.modalClientData.hide()
      })
    },
    dateTime(date) {
      return '<b>' + moment(date).format('HH:mm') + '</b> ' + moment(date).format('DD.MM.YYYY');
    },
    seatClicked(seatNumber) {
      this.$store.dispatch('MyBooks/book', {
        tripId: this.form.id,
        fromId: this.form.fromFilter.fromPointId,
        toId: this.form.fromFilter.toPointId,
        seatNumber
      }).then((data) => {
        if (data) {
          this.showUpdateTicket({ ticketId: data.id })
        }
      }).catch(error => {
        if (error.variant)
          this.$bvToast.toast(error.message, {
            title: error.title,
            autoHideDelay: 5000,
            variant: error.variant,
            appendToast: true
          });
      })
    },
    fillForm(form) {
      console.log('form')
      console.log(form)
      for(let field in form) {
        this.$set(this.form, field, form[field])
      }
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm (dddd)')
    }
  },
  computed: {
    ...mapState({
      countries: state => state.Countries.items,
      documentTypes: state => state.DocumentTypes.items,
      allBookings: state => state.MyBooks.bookings
    }),
    bookings: function() {

      if (!this.form.fromFilter)
        return {}

      if (this.form.fromFilter.fromPointId !== this.allBookings.fromId)
        return {}

      if (this.form.fromFilter.toPointId !== this.allBookings.toId)
        return {}

      if (this.form.detailedInfo.id !== this.allBookings.tripId)
        return {}

      return this.allBookings
    },
    bookingsTickets: function () {
      if (!this.bookings.tickets)
        return []

      return this.bookings.tickets
    },
    expiresIn: function() {
      if (!this.bookings.id)
        return null

      return moment.duration(moment.tz(this.bookings.expiredAt, process.env.VUE_APP_TZ).diff(moment.tz(process.env.VUE_APP_TZ))).format("HH:mm:ss")
    },
    tickets: function () {
      if (!this.form.detailedInfo)
        return []

      let tickets = []

      for (let order of this.form.detailedInfo.orders) {
        tickets = [ ...tickets, ...order.tickets ]
      }

      if (this.bookings.tickets) {
        tickets = [ ...tickets, ...this.bookings.tickets.map(ticket => ({ ...ticket, status: 'selected' })) ]
      }

      return tickets
    }
  },
  components: {
    UpdateTicket,
    BusSchema
  }
}
</script>

<style scoped>
  .booking-form {
    border: 1px solid #eee;
    padding: 17px;
    padding-bottom: 0;
    border-radius: 7px;
  }
  .expiration-time {
    font-size: 50px;
  }
  .selected {
    background-color: #9f3333;
    border-color: #9f3333 !important;
  }
  .noSale {
    background-color: #cccccc;
    border-color: #cccccc !important;
  }
  .paid {
    background-color: #3969c9;
    border-color: #3969c9 !important;
    color: #fff;
  }
  .booked {
    background-color: #ffdf94;
    border-color: #ffdf94 !important;
  }
  .seat {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    margin: 5px;
    width: 25px;
    height: 25px;
    text-align: center;
    border: 1px solid #eee;
  }
</style>