<template>
  <div>
    <b-modal
        id="accept-payment-modal"
        ref="acceptPaymentModal"
        title="Управление заказом"
        hide-footer
        size="xl"
        l
    >
      <b-overlay :show="loading" class="mb-4">
        <b-row v-if="order.trip">
          <b-col md="6">
            <b>Информация о рейсе</b>
            <table class="table mt-4">
              <tbody>
                <tr>
                  <td>Наименование перевозчика</td>
                  <td>{{order.trip.carrier.carrierName}}</td>
                </tr>
                <tr>
                  <td>Наименование и номер маршрута</td>
                  <td>{{order.trip.route.number}} - {{order.trip.route.name}}</td>
                </tr>
                <tr>
                  <td>Дата и время выезда</td>
                  <td>{{order.trip.startTime}}</td>
                </tr>
                <tr>
                  <td>Номер посадочной ведомости</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>До выезда рейса осталось</td>
                  <td>{{ timeToDeparture() }}</td>
                </tr>
              </tbody>
            </table>

            <b>Заказ</b>
            <table class="table mt-4">
              <tbody>
                <tr>
                  <td>Дата и время бронирования</td>
                  <td>{{order.createdAt}}</td>
                </tr>
                <tr>
                  <td>Номер заказа</td>
                  <td>{{order.number}}</td>
                </tr>
                <tr>
                  <td>Мобильный телефон</td>
                  <td>{{order.clientPhone}}</td>
                </tr>
                <tr>
                  <td>Надежность клиента</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Отправление</td>
                  <td>{{order.from.name}} {{order.trip.points[order.fromId].dateTime }}</td>
                </tr>
                <tr>
                  <td>Прибытие</td>
                  <td>{{order.to.name}} {{order.trip.points[order.toId].dateTime }}</td>
                </tr>
                <tr>
                  <td>Статус заказа</td>
                  <td>
                    <span v-if="order.status === 'booking'" class="status bg-danger">Забронировано</span>
                    <span v-else-if="order.status === 'removed'" class="status bg-secondary">Удалено</span>
                    <span v-else-if="order.status === 'paid'" class="status bg-success">Оплачено</span>
                    <span v-else-if="order.status === 'expired'" class="status bg-secondary">Вышло время бронирования</span>
                    <span v-else-if="order.status === 'prepared_booking'" class="status bg-primary">Активная бронь</span>
                  </td>
                </tr>
                <tr v-if="order.status === 'booking' || order.status === 'prepared_booking'">
                  <td>Бронь истекает через</td>
                  <td>
                    {{ timeExpiredAt() }}
                  </td>
                </tr>
                <tr>
                  <td>Статус оплаты</td>
                  <td>{{ (order.payment ? order.payment.status : 'Не оплачено' ) }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col md="6">
            <b>Сводная информация</b>
            <table class="table mt-4">
              <tbody>
              <tr>
                <td>Билетов</td>
                <td>{{ order.tickets.length }}</td>
              </tr>
              <tr>
                <td>Багажных мест</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Стоимость билетов и ручной клади</td>
                <td>{{ ticketsSummary() }}</td>
              </tr>
              <tr>
                <td>Сервисный сбор</td>
                <td>{{ serviceFeeSummary() }}</td>
              </tr>
              <tr>
                <td>Кассовый сбор</td>
                <td>{{ cashBoxFeeSummary() }}</td>
              </tr>
              <tr>
                <td>Полная стоимость заказа</td>
                <td>{{ fullAmount() }}</td>
              </tr>
              <tr>
                <td>Cлучае оплаты через кассу сервисный сбор не взимается. Итого к оплате через кассу</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Оплачено</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Сумма возврата</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Возврат осуществлен</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Комментарий
                  <p class="comment">{{order.comment}}</p>
                </td>
                <td>
                  <b-button size="sm" variant="success" @click="openEditComment">изменить</b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col md="12">
            <b-button v-if="!order.payment" variant="success" @click="payWithCash">Оплатить через кассу</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <b-modal
        id="edit-comment"
        ref="editComment"
        title="Изменить комментарий"
        hide-footer
        size="xl"
        l
    >
      <b-overlay :show="loadingComment" class="mb-4">
        <div class="b-form-group mb-3">
          <b-textarea class="input__comment" name="text" placeholder="Комментарий" required rows="5" v-model="order.comment"></b-textarea>
        </div>
        <div class="b-form-group">
          <b-button variant="primary" @click="saveComment">Сохранить</b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import OrdersApi from "@/api/orders.api";
import moment from "moment";
import {toDays} from "@/utils/time";
import {toRubles} from "@/utils/money";

export default {
  name: "AcceptPayment",
  data() {
    return {
      loading: false,
      loadingComment: false,
      order: {}
    }
  },
  methods: {
    saveComment() {
      this.loadingComment = true;

      OrdersApi.editComment({
        orderId: this.order.id,
        comment: this.order.comment
      })
          .finally(() => {
            this.loadingComment = false
            this.$refs.editComment.hide();
          })
    },
    openEditComment() {
      this.$refs.editComment.show();
    },
    reset() {
      this.order = {}
    },
    hide() {
      this.$refs.acceptPaymentModal.hide()
    },
    show(bookingId) {
      this.reset()
      this.loading = true
      this.$refs.acceptPaymentModal.show()

      return OrdersApi.myPreparedBooking({ orderId: bookingId })
          .then((response) => {
            this.order = response.data
            console.log('order')
            console.log(this.order)
          })
          .finally(() => this.loading = false)
    },
    timeToDeparture() {
      if (!this.order.trip)
        return ''

      const minutes = moment.tz(this.order.trip.startTime, process.env.VUE_APP_TZ).diff(moment.tz(process.env.VUE_APP_TZ), 'minutes')
      return toDays(minutes)
    },
    timeExpiredAt() {
      if (!this.order.expiredAt)
        return ''

      const minutes = moment.tz(this.order.expiredAt, process.env.VUE_APP_TZ).diff(moment.tz(process.env.VUE_APP_TZ), 'minutes')
      return toDays(minutes)
    },
    ticketsSummary() {
      return toRubles(this.order.tickets.reduce((partialSum, ticket) => partialSum + ticket.amount, 0))
    },
    serviceFeeSummary() {
      return toRubles(this.order.tickets.reduce((partialSum, ticket) => partialSum + ticket.serviceFee, 0))
    },
    cashBoxFeeSummary() {
      return toRubles(this.order.tickets.reduce((partialSum, ticket) => partialSum + ticket.cashBoxFee, 0))
    },
    fullAmount() {
      return toRubles(this.order.tickets.reduce((partialSum, ticket) => partialSum + ticket.cashBoxFee + ticket.serviceFee + ticket.amount, 0))
    },
    payWithCash() {
      this.loading = true

      OrdersApi.orderPayWithCash({ orderId: this.order.id })
          .then((response) => {
            if (response.data.status === 'success') {
              this.$bvToast.toast('Заказ был успешно оплачен через кассу!', {
                title: 'Успех',
                autoHideDelay: 5000,
                variant: 'success',
                appendToast: true
              })
              this.$store.dispatch('MyPreparedBookings/load')
              this.$store.dispatch('MySales/load')
              this.$store.dispatch('MyRefunds/load')
              return this.hide()
            }
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
  .status {
    padding: 3px 5px;
    border-radius: 3px;
    color: #fff;
  }
  .comment {
    margin-top: 20px;
    font-style: italic;
  }
</style>