<template>
  <div>
    <b-form-group label="Тип билета">
      <b-form-radio v-model="ticket.type" :value="'full'">Полный (для пассажиров 12 лет и старше)</b-form-radio>
      <b-form-radio v-model="ticket.type" :value="'child'">Детский (для пассажиров до 12 лет)</b-form-radio>
      <b-form-radio v-model="ticket.type" :value="'preferential'">Льготный билет</b-form-radio>
    </b-form-group>
    <b-button v-b-toggle.ticket-client-info variant="outline-primary">Указать персональные данные (необязательно)</b-button>
    <b-collapse id="ticket-client-info" class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group label="Фамилия">
            <b-form-input v-model="ticket.lastName"/>
          </b-form-group>
          <b-form-group label="Имя">
            <b-form-input v-model="ticket.name"/>
          </b-form-group>
          <b-form-group label="Отчество (при наличии)">
            <b-form-input v-model="ticket.middleName"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Тип документа">
            <b-form-select id="documentType" v-model="ticket.documentTypeId">
              <b-form-select-option :value="null" :key="null">Выберите тип документа</b-form-select-option>
              <b-form-select-option v-for="documentType in documentTypes" :value="documentType.id"
                                    :key="documentType.id">{{ documentType.document_type }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Серия и номер документа">
            <b-form-input v-model="ticket.documentNumber"/>
          </b-form-group>
          <b-form-group label="Дата рождения">
            <b-form-datepicker id="birthdate" v-model="ticket.birthdate" :start-weekday="1" locale="ru" label-no-date-selected="Дата рождения"/>
          </b-form-group>
          <b-form-group label="Пол">
            <b-form-radio v-model="ticket.gender" :value="1">Мужской</b-form-radio>
            <b-form-radio v-model="ticket.gender" :value="2">Женский</b-form-radio>
          </b-form-group>
          <b-form-group label="Гражданство">
            <b-form-select id="country" v-model="ticket.countryId">
              <b-form-select-option :value="null" :key="null">Выберите гражданство</b-form-select-option>
              <b-form-select-option v-for="country in countries" :value="country.id" :key="country.id">
                {{ country.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-collapse>
    <div class="text-right mt-4">
      <b-button variant="primary" @click="updateTicket">Подтвердить</b-button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "UpdateTicket",
  data() {
    return {
      ticket: {
        ticketId: null,
        type: 'full',
        isChild: false, // qwfqwf
        isPreferential: false, // qwf
        documentTypeId: null,
        documentNumber: null,
        lastName: null,
        middleName: null,
        name: null,
        birthdate: null,
        countryId: null,
        gender: null
      },
    }
  },
  methods: {
    resetTicketData() {
      this.ticket = {
        ticketId: null,
        type: 'full',
        isChild: false, // qwfqwf
        isPreferential: false, // qwfqwfqwf
        documentTypeId: null,
        documentNumber: null,
        lastName: null,
        middleName: null,
        name: null,
        birthdate: null,
        countryId: null,
        gender: null
      }
    },
    updateTicket() {
      this.$emit('update', this.ticket)
    },
    fillData(data) {
      if (!data)
        return

      this.ticket = {
        ...this.ticket,
        ...data
      }
    }
  },
  computed: {
    ...mapState({
      countries: state => state.Countries.items,
      documentTypes: state => state.DocumentTypes.items
    }),
  }
}
</script>

<style scoped>

</style>